import React from "react";

import Top20Skills from "./Top20Skills";
import Top20SkillsGraph from "./Top20SkillsGraph";
import { skillsDict } from "../../../utils/UIProvider";
import { useData } from "../../../components/shared/DataProvider";

export default function TopSkills() {
  const {
    isLoading: loading,
    topMatrics: { skills },
  } = useData();

  if (loading) return <div>Loading data</div>;

  const mapped = skills?.map((skill: any) => ({
    ...skill,
    skillName: skillsDict[skill!.code],
  }));

  return (
    <div className="row flex-row">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <Top20Skills skills={mapped} />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <Top20SkillsGraph skills={mapped} />
      </div>
    </div>
  );
}
