import React from "react";
import "./components.css";
import { getTime } from "../../../utils/helper";

function Top20Skills({ skills }: any) {
  const mid = Math.floor(skills?.length / 2);

  return (
    <div
      className="p-2 bg-white"
      style={{ borderRadius: "5px", height: "100%" }}
    >
      <h4 style={{ textAlign: "center" }}>Top 20 Skills</h4>
      <div className="w-100" />
      {skills?.length === 0 && (
        <div className="flex flex-column align-items-center justify-content-center flex-grow-1 text-center">
          <h1 style={{ fontSize: "40px" }}>No Data Found</h1>
        </div>
      )}
      {skills.length > 0 && (
        <div className="row">
          <div className="col-6">
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {skills?.slice(0, mid).map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{item.skillName}</td>
                      <td>{getTime(item.duration)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6">
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {skills?.slice(mid).map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{item.skillName}</td>
                      <td>{getTime(item.duration)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Top20Skills;
