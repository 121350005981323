import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Top20Skills from "../components/TopSkills/Top20Skills";
import UserMatricsGraph from "../components/UserMatricsGraph";
import "./dashboard.css";
import UserMatrics from "../components/UserMatrics";
import Top5Students from "../components/Top5Students";
import Leaderboard from "../components/Leaderboard";
import ParentEngagementGraph from "../components/ParentEngagementGraph";
import AllTypeGrid from "../components/AllTypeGrid";

import { FilterParams } from "../../types/index";
import TopSkills from "routes/components/TopSkills/TopSkills";
import { useData } from "../../components/shared/DataProvider";
import axios from "axios";
import { isAmericaUAdmin, isSkillCoach } from "../../utils/UIProvider";
import { useAuth } from "../../components/shared/AuthProvider";
import { useCallback } from "react";

function Dashboard() {
  const [filter, setFilter] = useState<FilterParams>({
    start: "",
    end: "",
    school: "",
    name: "",
    nickname: "",
    username: "",
  } as FilterParams);

  const { user } = useAuth();
  const { setLoading, setSchools, setTopMetrics, setDailyMetrics } = useData();

  const loadData = useCallback(async () => {
    setLoading(true);

    let query1 = "/dashboard/top-metrics";
    let query2 = "/dashboard/daily-metrics";

    if (filter?.start) {
      const start = new Date(filter.start);
      query1 += `?earlierTs=${Math.floor(start.getTime())}`;
      query2 += `?earlierTs=${Math.floor(start.getTime())}`;
    }

    if (filter?.end) {
      const end = new Date(filter.end);
      query1 += `${filter.start ? "&" : "?"}laterTs=${Math.floor(
        end.getTime()
      )}`;
      query2 += `${filter.start ? "&" : "?"}laterTs=${Math.floor(
        end.getTime()
      )}`;
    }

    if (filter?.school) {
      query1 += `${filter.start || filter.end ? "&" : "?"}schoolId=OH-${
        filter.school
      }`;

      query2 += `${filter.start || filter.end ? "&" : "?"}schoolId=OH-${
        filter.school
      }`;
    }

    const [topMatrics, dailyMetrics] = await Promise.all([
      axios.get(query1),
      axios.get(query2),
    ]);

    setDailyMetrics(dailyMetrics.data);

    if (topMatrics.data) {
      setTopMetrics(topMatrics.data);
    } else {
      setTopMetrics({
        skills: [],
        users: [],
        metrics: {
          totalMin: 0,
          avgMin: 0,
          totalSkillsMin: 0,
          totalLogins: 0,
          avgDailyLogins: 0,
        },
      });
    }

    setLoading(false);
  }, [filter, setDailyMetrics, setLoading, setTopMetrics]);

  useEffect(() => {
    loadData();
  }, [filter.school, filter.end, filter.start, loadData]);

  useEffect(() => {
    axios
      .get("/search/schools?fields=Building_Name,sk,Building_IRN,District_Name")
      .then((schoolsFetched) => {
        setSchools(schoolsFetched.data.schools);
      });
  }, [setSchools]);

  return (
    <div className="container">
      <div className="row">
        <Sidebar filters={filter} onFilter={(value) => setFilter(value)} />
        <div className="col-lg-9 col-md-9 col-sm-12">
          <div className="row flex-row">
            <div className="col-12">
              <UserMatricsGraph />
            </div>
          </div>
          <div className="w-100 mb-3" />
          <TopSkills />
          <div className="w-100 mb-3" />
          <div className="row flex-row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <UserMatrics />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Top5Students />
            </div>
          </div>

          {isAmericaUAdmin(user!.role) && (
            <>
              <div className="w-100 mb-3" />
              <div className="row flex-row">
                <div className="col-12">
                  <AllTypeGrid
                    filter={filter}
                    onFilter={(input) => setFilter({ ...filter, ...input })}
                    handleOnSearch={loadData}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
