import React from "react";
import AggregateBox from "./AggregateBox";
import FilterBox from "./FilterBox";
import { FilterParams } from "../../types/index";
import Button from "../../components/Button/Button";
import { useAuth } from "../../components/shared/AuthProvider";

function Sidebar({
  filters,
  onFilter,
}: {
  filters: FilterParams;
  onFilter: (value: FilterParams) => void;
}) {
  const { logout } = useAuth();

  return (
    <div className="col-lg-3 col-md-3 col-sm-12">
      <FilterBox filters={filters} onFilter={onFilter} />
      <div className="w-100" />
      <AggregateBox onFilter={onFilter} />
      <div className="w-100" />

      <div className="box profile-box">
        <Button
          style={{ marginBottom: 10 }}
          btnType="red"
          value="Logout"
          onClick={() => logout()}
        />
      </div>
    </div>
  );
}

export default Sidebar;
