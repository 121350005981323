export enum USER_ROLES {
  STUDENT = "0",
  PARENT = "100",
  TEACHER = "200",
  SKILL_COACH = "300",
  SCHOOL_ADMIN = "400",
  AMERICAU_ADMIN = "900",
}

export const ROLES: any = {
  "0": "Student",
  "100": "Parent",
  "200": "Teacher",
  "300": "Skill Coach",
  "400": "School Admin",
  "900": "AmericaU Admin",
};

export const isTeacher = (role: string | number): boolean => {
  return role >= USER_ROLES.TEACHER && role < USER_ROLES.SKILL_COACH;
};

export const isSkillCoach = (role: string | number): boolean => {
  return role >= USER_ROLES.SKILL_COACH && role < USER_ROLES.SCHOOL_ADMIN;
};

export const isParent = (role: string | number): boolean => {
  return role >= USER_ROLES.PARENT && role < USER_ROLES.TEACHER;
};

export const isSchoolAdmin = (role: string | number): boolean => {
  return role >= USER_ROLES.SCHOOL_ADMIN && role < USER_ROLES.AMERICAU_ADMIN;
};

export const isAmericaUAdmin = (role: string | number): boolean => {
  return role >= USER_ROLES.AMERICAU_ADMIN;
};

export const skillsDict: { [name: string]: string } = {
  T20S_1: "Social and Emotional Learning",
  T20S_2: "Character",
  T20S_3: "Creativity",
  T20S_4: "Learning Skills ",
  T20S_5: "Health and Wellness",
  T20S_6: "Resilience",
  T20S_7: "Distraction Management",
  T20S_8: "Tech, Hardware and Coding",
  T20S_9: "Trades Skill",
  T20S_10: "Cultural Understanding",
  T20S_11: "Civics Literacy",
  T20S_12: "Environmental Literacy",
  T20S_13: "Communication",
  T20S_14: "Teamwork",
  T20S_15: "Leadership",
  T20S_16: "Digital and Informational Literacy",
  T20S_17: "Entrepreneurship",
  T20S_18: "Problem Solving",
  T20S_19: "Financial Literacy",
  T20S_20: "Habit and Goal Setting",
};
