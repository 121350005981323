import React, { useCallback, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { useData } from "../../components/shared/DataProvider";
import { getTime } from "../../utils/helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          if (context.dataset.id == "LOGIN_COUNT") return context.raw;

          return (
            context.label + ", Total Time: " + getTime(context.raw as number)
          );
        },
      },
    },
  },
};

export default function UserMatricsGraph() {
  const { isLoading: loading, dailyMetrics: metrics } = useData();

  if (loading) return <div>Loading data</div>;

  const data = {
    labels: metrics.map((sk: any) => sk.day),
    datasets: [
      {
        label: "Hours In Game",
        data: metrics.map((met: any) => met.sec / (60 * 60)),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        id: "HOURS_IN_GAME",
      },
      {
        label: "Login Count",
        data: metrics.map((met: any) => met.logins),
        borderColor: "#01703d",
        backgroundColor: "#01703d45",
        id: "LOGIN_COUNT",
      },
    ],
  };

  return (
    <div className="p-2  bg-white" style={{ borderRadius: "5px" }}>
      <h4 style={{ textAlign: "center" }}>User Metrics Graph</h4>

      {metrics.length > 0 && <Line options={options} data={data} />}
      {metrics.length === 0 && (
        <h1 className="text-center py-4">No Data Found!</h1>
      )}
    </div>
  );
}
