import React from "react";
import "./components.css";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../../components/shared/AuthProvider";
import { FilterParams } from "../../types/index";
import { isAmericaUAdmin } from "../../utils/UIProvider";
import ComboFilter from "./ComboFilter";
import { useData } from "../../components/shared/DataProvider";

function FilterBox({
  filters,
  onFilter,
}: {
  filters: FilterParams;
  onFilter: (value: FilterParams) => void;
}) {
  const { schools } = useData();
  const { user } = useAuth();
  const role = user?.role;

  const school = schools.find((s: any) => s.Building_IRN == filters.school);

  return (
    <div className="box filter_box">
      {!schools.length && (
        <Skeleton
          count={5}
          height={50}
          baseColor="#00a4eb"
          highlightColor="#0fb7ff"
        />
      )}

      {schools.length && (
        <>
          <div className="form-group mb-3">
            <label htmlFor="start-date">Start Date</label>
            <input
              value={filters.start}
              onChange={(e) =>
                onFilter({ ...filters, start: e.currentTarget.value })
              }
              type="date"
              className="form-control"
              name="start-date"
              id="start-date"
              placeholder="Start date"
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="end-date">End Date</label>
            <input
              value={filters.end}
              onChange={(e) =>
                onFilter({ ...filters, end: e.currentTarget.value })
              }
              type="date"
              className="form-control"
              name="end-date"
              id="end-date"
              placeholder="End date"
            />
          </div>

          {isAmericaUAdmin(role as string) && (
            <div className="form-group mb-3">
              <ComboFilter
                placeholder="Select school"
                label={"School"}
                value={(() => {
                  if (filters.school != "0" && filters.school == "") return "";

                  return {
                    value: filters.school,
                    label:
                      school?.Building_Name + " - " + school?.District_Name,
                  };
                })()}
                onChange={(school) => {
                  school
                    ? onFilter({
                        ...filters,
                        name: "",
                        nickname: "",
                        username: "",
                        school: school.value,
                      })
                    : onFilter({
                        ...filters,
                        name: "",
                        nickname: "",
                        username: "",
                        school: "",
                      });
                }}
                items={schools.map((s: any) => ({
                  value: s.Building_IRN,
                  label: s.Building_Name + " - " + s.District_Name,
                }))}
                isClearable={true}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FilterBox;
