import React, { CSSProperties } from "react";
import "./button.css";

type ButtonProps = {
  value: string;
  btnType?: "green" | "red";
  onClick?: Function;
  style?: CSSProperties;
};

const Button: React.FC<ButtonProps> = ({
  style,
  onClick,
  value,
  btnType: type = "green",
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <div
      onClick={onClick as React.MouseEventHandler<HTMLDivElement>}
      style={style}
      className={`btn-americau ${type === "green" ? "green-btn" : "red-btn"}`}
    >
      <a>{value}</a>
    </div>
  );
};

export default Button;
