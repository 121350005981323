import React, { useCallback, useContext, useState } from "react";
import { TopMetrics, Metrics, DailyMetrics } from "../../types/index";

type IDataContext = {
  topMatrics: TopMetrics;
  users: any[];
  schools: any[];
  isLoading: boolean;
  dailyMetrics: DailyMetrics[];
  setUsers: (a: any[]) => void;
  setSchools: (a: any[]) => void;
  setTopMetrics: (a: TopMetrics) => void;
  setLoading: (a: boolean) => void;
  setDailyMetrics: (a: DailyMetrics[]) => void;
  clear: () => void;
};

const DataProviderContext = React.createContext<IDataContext>(
  {} as IDataContext
);

type DataProviderProps = {
  children: React.ReactNode;
};

export const DataProvider = ({ children }: DataProviderProps): JSX.Element => {
  const [data, setData] = useState<{
    users: any[];
    schools: any[];
    dailyMetrics: DailyMetrics[];
    topMatrics: TopMetrics;
    isLoading: boolean;
  }>({
    schools: [],
    users: [],
    dailyMetrics: [],
    topMatrics: { skills: [], users: [], metrics: {} as Metrics } as TopMetrics,
    isLoading: true,
  });

  const setUsers = useCallback((a: any[]) => {
    setData((prev) => ({ ...prev, users: a }));
  }, []);

  const setSchools = useCallback((a: any[]) => {
    setData((prev) => ({ ...prev, schools: a }));
  }, []);

  const setTopMetrics = useCallback((a: TopMetrics) => {
    setData((prev) => ({ ...prev, topMatrics: a }));
  }, []);

  const setDailyMetrics = useCallback((a: DailyMetrics[]) => {
    setData((prev) => ({ ...prev, dailyMetrics: a }));
  }, []);

  const setLoading = useCallback((a: boolean) => {
    setData((prev) => ({ ...prev, isLoading: a }));
  }, []);

  const clear = () => {
    setData({
      dailyMetrics: [],
      users: [],
      schools: data.schools,
      topMatrics: {} as TopMetrics,
      isLoading: false,
    });
  };

  return (
    <DataProviderContext.Provider
      value={{
        isLoading: data.isLoading,
        topMatrics: data.topMatrics,
        schools: data.schools,
        users: data.users,
        dailyMetrics: data.dailyMetrics,
        setDailyMetrics,
        setUsers,
        setSchools,
        setTopMetrics,
        setLoading,
        clear,
      }}
    >
      {children}
    </DataProviderContext.Provider>
  );
};

export const useData = (): IDataContext => {
  return useContext(DataProviderContext);
};
