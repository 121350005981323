import React, { useCallback, useContext, useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import moment from "moment";
import setupAxios from "utils/axios";
import { useData } from "./DataProvider";
import axios from "axios";
import { USER_ROLES } from "../../utils/UIProvider";

export interface IUser {
  l_name: string;
  f_name: string;
  nickname: string;
  username: string;
  s_id: string;
  email: string;
  uid: string;
  role: string;
  iat?: string;
  imageUrl: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
}

interface IAuth {
  user: null | IUser;
  logout: Function;
  login: (token: string) => void;
}

const AuthContext = React.createContext<IAuth>({} as IAuth);

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<IUser | null>(null);
  const { setSchools, setUsers } = useData();

  const logout = useCallback(() => {
    setUser(null);
    setSchools([]);
    setUsers([]);

    //remove token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }, [setSchools, setUsers]);

  useEffect(() => {}, [logout]);

  const login = async (token: string) => {
    try {
      const decoded: any = jwtDecode(token);

      const expiry = moment(decoded.exp * 1000);

      if (expiry.isAfter(moment())) {
        const { data } = await axios.get("https://api.americau.com/user", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (data.role >= USER_ROLES.SKILL_COACH) {
          setUser(data);

          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(data));

          setupAxios();
        } else {
          window.alert("You are not authorized to login data dashboard.");
        }
      } else {
        logout();
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuth => {
  return useContext(AuthContext);
};
