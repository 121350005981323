import Button from "components/Button/Button";
import dayjs from "dayjs";

function AggregateBox({ onFilter }: any) {
  return (
    <div className="box aggregate_box">
      <h5 className="mb-3">Select All Schools, All Grades</h5>
      <Button
        style={{ marginBottom: 10 }}
        value="Last 24 Hours"
        onClick={() => {
          // onFilter({
          //   start: dayjs().format("YYYY-MM-DD"),
          //   end: "",
          //   school: "",
          // });

          window.alert("Not implemented yet.");
        }}
      />
      <Button
        style={{ marginBottom: 10 }}
        value="Last 7 Days"
        onClick={() =>
          onFilter({
            start: dayjs().add(-7, "day").format("YYYY-MM-DD"),
            end: "",
            school: "",
          })
        }
      />
      <Button
        style={{ marginBottom: 10 }}
        value="Last 14 Days"
        onClick={() =>
          onFilter({
            start: dayjs().add(-14, "day").format("YYYY-MM-DD"),
            end: "",
            school: "",
          })
        }
      />
      <Button
        style={{ marginBottom: 10 }}
        value="Last 30 Days"
        onClick={() =>
          onFilter({
            start: dayjs().add(-30, "day").format("YYYY-MM-DD"),
            end: "",
            school: "",
          })
        }
      />
    </div>
  );
}

export default AggregateBox;
