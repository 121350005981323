import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { FilterParams } from "../../types/index";
import { useData } from "../../components/shared/DataProvider";
import AppInput from "./AppInput";
import axios from "axios";
import Spinner from "components/shared/Spinner";

const Table = React.forwardRef(
  ({ columns, data }: { columns: any; data: any }, ref) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      prepareRow,
      state: { pageIndex, pageSize, ...allOtherState },
      ...all
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useSortBy,
      usePagination
    );

    useImperativeHandle(ref, () => ({
      ...all,
      state: { pageIndex, pageSize, ...allOtherState },
      headerGroups,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      getTableProps,
      getTableBodyProps,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      prepareRow,
    }));

    return (
      <div>
        <div className="table-responsive">
          <table className="table" {...getTableProps()}>
            <thead>
              {headerGroups.slice(1).map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th {...column.getHeaderProps()} key={index}>
                      <span {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                      {column.canFilter ? column.render("Filter") : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="pagination d-flex flex-row justify-content-between align-items-center mt-3">
          <nav>
            <ul className="pagination">
              <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
                <button onClick={() => previousPage()} className="page-link">
                  Previous
                </button>
              </li>
              {Array.from(Array(pageOptions.length).keys())
                .slice(
                  pageIndex - 1 < 0 ? 0 : pageIndex - 1,
                  pageIndex - 1 < 0 ? pageIndex + 3 : pageIndex + 2
                )
                .map((num) => {
                  return (
                    <li
                      className={`page-item ${
                        num == pageIndex ? "active" : ""
                      }`}
                    >
                      <button
                        onClick={() => gotoPage(num)}
                        className="page-link"
                      >
                        {num + 1}
                      </button>
                    </li>
                  );
                })}

              <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
                <button onClick={() => nextPage()} className="page-link">
                  Next
                </button>
              </li>
            </ul>
          </nav>

          <div className="d-flex flex-row">
            <p>
              Page:{" "}
              <strong>
                {"   "}
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </p>
            <div className="form-group" style={{ marginLeft: "10px" }}>
              <select
                className="custom-select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const DataTable = React.forwardRef(
  (
    {
      filter,
      onFilter,
    }: {
      filter: FilterParams;
      onFilter: (text: any) => void;
      onSearch: React.MouseEventHandler<HTMLButtonElement>;
    },
    ref
  ) => {
    const [loading, setLoading] = useState(false);
    const { users, setUsers, schools } = useData();

    const columns = React.useMemo(
      () => [
        {
          Header: "Name",
          columns: [
            {
              Header: "First Name",
              accessor: "f_name",
              filter: "fuzzyText",
            },
            {
              Header: "Last Name",
              accessor: "l_name",
              filter: "fuzzyText",
            },
          ],
        },
        {
          Header: "Info",
          columns: [
            {
              Header: "Nickname",
              accessor: "nickname",
            },
            {
              Header: "Chat",
              accessor: "chat",
              Cell: (props: any) => (props.value ? "Enabled" : "Disabled"),
            },
            {
              Header: "Signup Date",
              accessor: "iat",
              Cell: (props: any) =>
                props?.value
                  ? new Date(props?.value).toLocaleString("en-US", {
                      weekday: "short",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour12: true,
                      minute: "numeric",
                      hour: "numeric",
                    })
                  : "nill",
            },
            {
              Header: "Username",
              accessor: "username",
            },
            {
              Header: "School",
              accessor: "s_id",
              Cell: (props: any) =>
                schools.find((sc) => sc.Building_IRN == props.value)
                  ?.Building_Name || "",
            },
          ],
        },
      ],
      []
    );

    const data = users;

    const fetchUsers = useCallback(
      async ({
        username,
        name,
        nickname,
        school,
      }: {
        username?: string;
        name?: string;
        nickname?: string;
        school?: string;
      }) => {
        let query3 = "/search/users";

        if (school) query3 += `?s_id=${school}`;

        if (username) {
          query3 += `${school ? "&" : "?"}username=${username}`;
        }

        if (name) {
          query3 += `${school || username ? "&" : "?"}name=${name}`;
        }

        if (nickname) {
          query3 += `${
            school || username || name ? "&" : "?"
          }nickname=${nickname}`;
        }

        try {
          const resp: any = await axios.get(query3);
          setUsers(resp.data.users);
        } catch (error: any) {
          return window.alert(error.message);
        }
      },
      []
    );

    const makeSearch = async () => {
      setLoading(true);

      await fetchUsers({
        username: filter?.username,
        nickname: filter?.nickname,
        name: filter?.name,
        school: filter?.school,
      });

      setLoading(false);
    };

    useEffect(() => {
      fetchUsers({ school: filter.school });
    }, [fetchUsers, filter.school]);

    return (
      <div
        className="p-2  bg-white"
        style={{ borderRadius: "5px", minHeight: 600 }}
      >
        <h4 style={{ textAlign: "center" }}>Search Students</h4>{" "}
        <div className="row mb-3">
          <div className="col-3">
            <AppInput
              value={filter.name}
              placeholder="Full name"
              onChange={(e) => onFilter({ name: e.currentTarget.value })}
            />
          </div>
          <div className="col-3">
            <AppInput
              value={filter.username}
              placeholder="Username"
              onChange={(e) => onFilter({ username: e.currentTarget.value })}
            />
          </div>
          <div className="col-3">
            <AppInput
              value={filter?.nickname}
              placeholder="Nickname"
              onChange={(e) => onFilter({ nickname: e.currentTarget.value })}
            />
          </div>
          <div className="col-3">
            <button
              className="btn btn-primary w-100"
              type="button"
              onClick={makeSearch}
            >
              Search
            </button>
          </div>
        </div>
        {loading && <Spinner />}
        {!loading && <Table ref={ref} columns={columns} data={data} />}
      </div>
    );
  }
);

export default DataTable;
