import * as React from "react";
import { useAuth } from "./AuthProvider";

export const ProtectedRoute = ({ children }: { children: any }) => {
  const { user } = useAuth();

  if (!user) return (window.location.href = "https://portal.americau.com");

  return children;
};
