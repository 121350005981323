import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";

import { AuthProvider } from "components/shared/AuthProvider";

import Dashboard from "routes/dashboard/Dashboard";
import DashboardBase from "components/shared/DashboardBase";
import "react-loading-skeleton/dist/skeleton.css";
import { ProtectedRoute } from "components/shared/ProtectedRoute";
import { DataProvider } from "components/shared/DataProvider";

import Authenticator from "../routes/Authenticator/Authenticator";

function App() {
  return (
    <DataProvider>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Authenticator />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardBase>
                  <Dashboard />
                </DashboardBase>
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </DataProvider>
  );
}

export default App;
