import React from "react";
import HeaderImage from "assets/shared/bannerV2.png";
function DashboardBase({ children }: { children: React.ReactNode }) {
  return (
    <div className="container-fluid dashboard-container">
      <header className="pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src={HeaderImage} className="img-fluid" alt="Header" />
            </div>
          </div>
        </div>
      </header>
      <div className="bg-transparent">
        <div>{children}</div>
      </div>
    </div>
  );
}

export default DashboardBase;
