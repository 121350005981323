import React from "react";
import { getTime } from "../../utils/helper";
import { useData } from "../../components/shared/DataProvider";

const getFullName = (user: any) => {
  if (user?.f_name && user?.l_name)
    return user?.f_name + " " + user?.l_name + " - ";

  if (user?.f_name) return user?.f_name;

  if (user?.l_name) return user?.l_name;

  if (user?.nickname) return user?.nickname + " - ";

  if (user?.username) return user?.username + " - ";

  return "";
};

function Top5Students() {
  const {
    isLoading,
    topMatrics: { users },
  } = useData();

  const students = users;

  if (isLoading) return <div>Loading data</div>;

  return (
    <div
      className="p-2 bg-white flex flex-column justify-content-center align-items-center"
      style={{ borderRadius: "5px", height: "100%" }}
    >
      <h4 style={{ textAlign: "center" }}>Top 5 Students</h4>
      <div className="w-100" />

      {students?.length === 0 && (
        <div className="flex flex-column align-items-center justify-content-center flex-grow-1 text-center">
          <h1 style={{ fontSize: "40px" }}>No Students Found</h1>
        </div>
      )}
      {students?.length > 0 && (
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {students?.map((st: any, index) => (
                <tr>
                  <th>{index + 1}</th>
                  <td>
                    {getFullName(st.student)}{" "}
                    {st?.points && st.points + " Pts -"}
                    {getTime(st.duration)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Top5Students;
