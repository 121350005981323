import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { FilterParams } from "../../types/index";
import { getTime } from "../../utils/helper";
import { useData } from "../../components/shared/DataProvider";

function UserMatrics({ filter }: { filter?: FilterParams }) {
  const {
    isLoading,
    topMatrics: { metrics },
  } = useData();

  if (isLoading) return <div>Loading metrics</div>;

  return (
    <div
      className="p-2 bg-white"
      style={{ borderRadius: "5px", height: "100%" }}
    >
      <h4 style={{ textAlign: "center" }}>User Metrics</h4>
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <th>Total Time in Game</th>
              <td>
                {metrics?.totalMin
                  ? getTime(metrics?.totalMin.toFixed(2))
                  : "--"}
              </td>
            </tr>
            <tr>
              <th>Average Minutes per session</th>
              <td>{metrics?.avgMin ? metrics?.avgMin.toFixed(2) : "--"}</td>
            </tr>
            <tr>
              <th>Total Time on Skills</th>
              <td>
                {metrics?.totalSkillsMin
                  ? getTime(metrics?.totalSkillsMin?.toFixed(2))
                  : "--"}
              </td>
            </tr>
            <tr>
              <th>Total Logins</th>
              <td>{metrics?.totalLogins || "--"}</td>
            </tr>
            <tr>
              <th>Average Daily Logins</th>
              <td>
                {metrics?.avgDailyLogins
                  ? Math.round(metrics.avgDailyLogins)
                  : "--"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserMatrics;
