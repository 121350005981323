import React from "react";
import "./components.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { getTime } from "utils/helper";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.7)",
        "rgba(54, 162, 235, 0.7)",
        "rgba(255, 206, 86, 0.7)",
        "rgba(75, 192, 192, 0.7)",
        "rgba(153, 102, 255, 0.7)",
        "rgba(255, 159, 64, 0.7)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

function Top20SkillsGraph({ skills }: any) {
  const getRandomColor = () => {
    var rint = Math.floor(0x100000000 * Math.random());

    return (
      "rgba(" +
      (rint & 255) +
      "," +
      ((rint >> 8) & 255) +
      "," +
      ((rint >> 16) & 255) +
      "," +
      ((rint >> 24) & 255) / 255 +
      ")"
    );
  };

  const data = {
    labels: skills?.map((sk: any) => sk.skillName),
    datasets: [
      {
        label: "# of Votes",
        data: skills?.map((sk: any) => sk.duration),
        backgroundColor: skills?.map(getRandomColor),
      },
    ],
  };

  return (
    <div className="p-2  bg-white" style={{ borderRadius: "5px" }}>
      <h4 style={{ textAlign: "center" }}>Top 20 Skills</h4>
      <div className="w-100" />

      {skills?.length === 0 && (
        <div className="flex flex-column align-items-center justify-content-center flex-grow-1 text-center">
          <h1 style={{ fontSize: "40px" }}>No Data Found</h1>
        </div>
      )}
      {skills.length > 0 && (
        <div style={{ height: "90%" }}>
          <Pie
            data={data}
            options={{
              responsive: true,

              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: (item) => {
                      return item.label + ": " + getTime(item.raw as number);
                    },
                  },
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Top20SkillsGraph;
