import React, { useEffect } from "react";
import Spinner from "components/shared/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/shared/AuthProvider";

export default function Authenticator() {
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const token = searchParams.get("token") || localStorage.getItem("token");

    const validateUser = async () => {
      await login(token!);

      navigate("/dashboard");
    };

    if (token) {
      try {
        validateUser();
      } catch (error) {
        navigate("login");
      }
    } else {
      window.location.href = "https://portal.americau.com";
    }
  }, [login, navigate, searchParams]);
  return (
    <div className="">
      <Spinner />
    </div>
  );
}
