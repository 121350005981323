import React, { useRef } from "react";
import { TableInstance } from "react-table";
import DataTable from "./DataTable";
import { FilterParams } from "../../types/index";

type Props = {
  filter: FilterParams;
  onFilter: (a: any) => void;
  handleOnSearch: React.MouseEventHandler<HTMLButtonElement>;
};

export default function AllTypeGrid({
  filter,
  onFilter,
  handleOnSearch,
}: Props) {
  const tableInstance = useRef<TableInstance>(null);

  return (
    <DataTable
      filter={filter}
      ref={tableInstance}
      onFilter={onFilter}
      onSearch={handleOnSearch}
    />
  );
}
