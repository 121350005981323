import React from "react";
import VirtualList from "react-tiny-virtual-list";
import { components, createFilter } from "react-select";
import Select from "react-select";

export default function ComboFilter({
  value,
  onChange,
  label,
  items,
  placeholder,
  disabled,
  ...all
}: {
  value: any;
  items: any[];
  onChange: (item: any) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
} & React.ComponentProps<Select>) {
  return (
    <div className="flex-1">
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <Select
        isDisabled={disabled}
        className="text-sm mt-1 focus:outline-indigo-800"
        value={value}
        onChange={onChange}
        styles={{
          menu: (base) => ({ ...base, width: "400px" }),
        }}
        components={{
          Option: CustomOption,
          MenuList: CustomMenuList as any,
        }}
        options={items}
        filterOption={createFilter({ ignoreAccents: false })}
        isClearable
        placeholder={placeholder}
        {...all}
      />
    </div>
  );
}

const CustomOption = ({ children, ...props }: any) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className="custom-option">
      {children}
    </components.Option>
  );
};

const DefaultItemHeight = 60;

class CustomMenuList extends React.Component {
  renderItem = (props: any) => {
    const { children }: any = this.props;

    if (Array.isArray(children)) {
      return (
        <li
          style={{
            ...props.style,
            listStyle: "none",
            borderBottom: "solid",
            borderBottomColor: "black",
            borderBottomWidth: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
          key={props.index}
        >
          {children[props.index]}
        </li>
      );
    }

    return (
      <li style={{ lineHeight: 40, height: 40 }} key={props.index}>
        {children.props.children}
      </li>
    );
  };

  render() {
    const { options, children, getValue }: any = this.props;

    const [value] = getValue();
    const initialOffset = options.indexOf(value) + 10;
    const childrenOptions = React.Children.toArray(children);
    const wrapperHeight = 300;

    return (
      <span className="react-virtualized-list-wrapper">
        <VirtualList
          width="100%"
          height={wrapperHeight}
          scrollOffset={initialOffset}
          itemCount={childrenOptions.length}
          itemSize={DefaultItemHeight}
          renderItem={this.renderItem}
        />
      </span>
    );
  }
}
